import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import { Outlet, useLocation } from 'react-router-dom';
import ColorSchemeToggle from 'views/layout/private/ColorSchemeToggle';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import cart from 'assets/cart.jpg';

import { Theme } from '@mui/joy';
import { useAppContext } from 'contexts/AppContext';

const globalSx = {
    ':root': {
        '--Form-maxWidth': '800px',
        '--Transition-duration': '0.4s',
    },
}

const containerSx = (theme: Theme) => ({
    width: { xs: '100%', md: '50vw' },
    transition: 'width var(--Transition-duration)',
    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    backdropFilter: 'blur(12px)',
    backgroundColor: 'rgba(255 255 255 / 0.2)',
    [theme.getColorSchemeSelector('dark')]: {
        backgroundColor: 'rgba(19 19 24 / 0.4)',
    },
});

const contentSx = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100dvh',
    width: '100%',
    px: 2,
}

const headerSx = {
    py: 3,
    display: 'flex',
    justifyContent: 'space-between',
}

const imageSx = (theme: Theme) => ({
    height: '100%',
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    left: { xs: 0, md: '50vw' },
    transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
    backgroundColor: 'background.level1',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(https://gottit-media.b-cdn.net/s/welcome_bg1.png)`,
    [theme.getColorSchemeSelector('dark')]: {
        backgroundImage: `url(https://gottit-media.b-cdn.net/s/welcome_bg2.png)`,
    },
})

const PublicLayout = () => {
    const location = useLocation();
    const version = localStorage.getItem("GOTTIT_VERSION");

    if(location.pathname === "/") {
        window.location.replace("/dashboard");
    }
    
    return (
        <React.Fragment>
            <GlobalStyles styles={globalSx} />

            <Box sx={containerSx}>
                <Box sx={contentSx}>
                    <Box component="header" sx={headerSx}>
                        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <IconButton variant="plain" color="primary" size="sm">
                                <img src="https://gottit-media.b-cdn.net/s/GOTTIT-LOGO.PNG" style={{ width: "100%", maxWidth: "130px"}}/> 
                            </IconButton>
                        </Box>
                        <ColorSchemeToggle />
                    </Box>

                    <Outlet />

                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                           {version !== null ? `v.${version}` : null} © Gottit {new Date().getFullYear()} | Tritonica Group Ltd.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={imageSx} />
        </React.Fragment>
    );
}

export default PublicLayout