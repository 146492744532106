import { IAuthPayload } from "views/pages/public/hooks/useAuthenticate";
import { IParams, tryFetch } from "../helpers/FetchAPI"
import { ISignUp } from "views/pages/public/hooks/useSignUp";

export const initialize = async () => {
    const params: IParams = {
        url: "/init",
        method: "GET"
    }
    return await tryFetch(params);
}

export const getModules = async (token: string) => {
    const params: IParams = {
        url: "/g/modules",
        method: "POST",
        token
    }
    return await tryFetch(params)
}

export const keepAlive = async (token: string) => {
    const params: IParams = {
        url: "/keep-alive",
        method: "POST",
        token
    }
    return await tryFetch(params)
}

export const refreshToken = async () => {
    const params: IParams = {
        url: "/refresh",
        method: "POST"
    }
    return await tryFetch(params)
}

export const tryVerifyToken = async (payload: any) => {
	const params: IParams = {
		url: "/verify-token",
		method: "POST",
        payload
	}
	return await tryFetch(params)
}

export const tryAuthenticate = async (payload: IAuthPayload) => {
    const params: IParams = {
        url: "/security/sign-in",
        method: "POST",
        payload
    }
    return await tryFetch(params)
}

export const trySignUp = async (payload: ISignUp) => {
    const params: IParams = {
        url: "/security/sign-up",
        method: "POST",
        payload
    }
    return await tryFetch(params)
}

export const trySetPassword = async (payload: any) => {
	const params: IParams = {
		url: "/security/set-password",
		method: "POST",
        payload
	}
	return await tryFetch(params)
}

export const trySignOut = async (token: string) => {
	const params: IParams = {
		url: "/g/sign-out",
		method: "POST",
		token
	}
	return await tryFetch(params)
}