import React from 'react'
import { trySignOut } from 'actions/security';
import { enqueueSnackbar } from 'notistack';
import { pageAlert } from 'views/layout/private/utils';
import TAlert from 'components/common/TAlert';

export const useSignOut = () => {
    const signout = async (token: string) => {
        const response:any = await trySignOut(token);
        if(!response.is_success) {
            pageAlert(<TAlert message={response.errors[0]} variant="error" />, "error");
        }
    }

    return { signout}
}