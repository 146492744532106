import { clearNotifications, readNotifications } from 'actions/user'
import TAlert from 'components/common/TAlert';
import React from 'react'
import { pageAlert } from 'views/layout/private/utils';

export const useNotificationAction = (token: string) => {
    const clear = async () => {
        const result:any = await clearNotifications(token);
        if(!result.is_success) {
            pageAlert(<TAlert message={result.errors[0]} variant="error" />, "error");
        }
    }

    const read = async () => {
        const result:any = await readNotifications(token);
        if(!result.is_success) {
            pageAlert(<TAlert message={result.errors[0]} variant="error" />, "error");
        }
    }
    
    return { clear, read }
}