import { Campaign } from '@mui/icons-material'
import { Box, Divider, Sheet, Stack, Typography } from '@mui/joy'
import { INotification } from 'core/hooks/useNotifications'
import { toHumanDate } from 'helpers/DMoment'
import React from 'react'

interface IProps {
    data: INotification[],
    count: number
}

const Messages = (props: IProps) => {
    const { data, count } = props;

    return (
        <Stack
            spacing={2}
            sx={{
                height: "calc(100% - 100px)"
            }}
        >
            <Typography level="title-lg">Notifications {count > 0 ? `(${count})` : null}</Typography>
            <Divider />
            <Box sx={{
                height: "100%",
                overflowY: "auto"
            }}>
                {data.length > 0 ? data.map((d: INotification) => (
                    <React.Fragment key={d.id}>
                        <Sheet
                            variant="plain"
                            sx={{ p: 1 }}
                        >
                            <Typography level="body-xs" sx={{mb:1}}>
                                {toHumanDate(d.created_at, "UTC")}
                            </Typography>

                            <Typography level={d.is_read ? "body-sm" : "title-sm"} fontWeight={600}>
                                {d.subject}
                            </Typography>

                            <Typography level="body-xs">
                                {d.message}
                            </Typography>
                        </Sheet>
                        <Divider />
                    </React.Fragment>
                )) : <EmptyNotifications/>}
            </Box>
        </Stack>
    )
}

const EmptyNotifications = () => {
    const sx = {
        p: 2,
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px"
    }

    return (
        <Box component="section" sx={sx}>
            <Stack alignItems="center" justifyContent="center" spacing={2} width="100%">
                <Campaign sx={{ fontSize: "4rem", opacity: 0.5 }} />
                <Typography level="body-md" color="neutral">
                    No notifications at the moment.
                </Typography>
            </Stack>
        </Box>
    )
}

export default Messages