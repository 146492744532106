import { extendTheme } from "@mui/joy";

export const gottit = extendTheme({
	components: {
		JoyListItem: {
			styleOverrides: {
				root: {
					'&:hover': {
					  backgroundColor: 'inherit',
					  color: 'inherit',
					},
				}
			}
		},
		JoyListItemButton: {
			styleOverrides: {
				root: {
					'&:hover': {
					  backgroundColor: 'inherit',
					  color: 'inherit',
					},
				}
			}
		},
		JoyButton: {
			styleOverrides: {
				root: {
				},
			}
		},
		JoyIconButton: {
			styleOverrides: {
				root: {
					'&:hover': {
					  backgroundColor: 'transparent',
					  color: 'inherit',
					},
				  },
			}
		}
	},
	"colorSchemes": {
		"light": {
			"palette": {
				"primary": {
					"50": "#faf5ff",
					"100": "#f3e8ff",
					"200": "#e9d5ff",
					"300": "#d8b4fe",
					"400": "#c084fc",
					"500": "#a855f7",
					"600": "#9333ea",
					"700": "#7e22ce",
					"800": "#6b21a8",
					"900": "#581c87"
				}
			}
		},
		"dark": {
			"palette": {
				"primary": {
					"50": "#faf5ff",
					"100": "#f3e8ff",
					"200": "#e9d5ff",
					"300": "#d8b4fe",
					"400": "#c084fc",
					"500": "#a855f7",
					"600": "#9333ea",
					"700": "#7e22ce",
					"800": "#6b21a8",
					"900": "#581c87"
				}
			}
		}
	}
});