import React from 'react'
import { Box } from '@mui/joy'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Breadcrumbs from './Breadcrumbs'
import Sidebar from './Sidebar'

const MainLayout = () => {

    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Header />
            <Sidebar />

            <Box
                component="main"
                className="MainContent"
                sx={{
                    px: { xs: 2, md: 6 },
                    pt: {
                        xs: 'calc(12px + var(--Header-height))',
                        sm: 'calc(12px + var(--Header-height))',
                        md: 'calc(12px + 65px)',
                    },
                    pb: { xs: 2, sm: 2, md: 3 },
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    height: '100dvh',
                    gap: 1,
                    overflowY: 'auto',
                    backgroundColor: (theme) => theme.palette.background.level1
                }}
            >
                <Breadcrumbs />
                <Outlet />
            </Box>
        </Box>
    )
}

export default MainLayout