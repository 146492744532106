import CryptoJS from "crypto-js";

export const hash = (text: string) => {
    const result = CryptoJS.MD5(text).toString();
    return result;
}

export const nonce = () => {
    return Math.random().toString(36).substring(2, 9);
}

export const ellipsify = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength - 3) + '...';
    }
}

export const toMoneyFormat = (amount: number, currency="PHP", locale="en-PH") => {
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency
    });

    return formatter.format(amount);
}