import { clearNotifications, readNotifications } from 'actions/user'
import { enqueueSnackbar } from 'notistack';
import React from 'react'

export const useNotificationAction = (token: string) => {
    const clear = async () => {
        const result:any = await clearNotifications(token);
        if(!result.is_success) {
            enqueueSnackbar(result.erros[0], { variant: "error"});
        }
    }

    const read = async () => {
        const result:any = await readNotifications(token);
        if(!result.is_success) {
            enqueueSnackbar(result.erros[0], { variant: "error"});
        }
    }
    
    return { clear, read }
}