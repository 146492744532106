import { Box, CircularProgress } from '@mui/joy'
import React from 'react'

const PageLoading = () => {
    return (
        <Box sx={{ width: "100%", height: "100%" }} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress
                sx={{
                    "--CircularProgress-size": "50px",
                    "--CircularProgress-trackThickness": "2px",
                    "--CircularProgress-progressThickness": "2px"
                }}
            />
        </Box>
    )
}

export default PageLoading