import { CheckRounded, ErrorRounded, NotificationsRounded } from '@mui/icons-material';
import { Stack, Theme, Typography } from '@mui/joy'
import { capitalizeFirstLetter } from 'helpers/String';
import React from 'react'

interface IProps {
    message: string,
    variant: "info" | "default" | "error" | "warning" | "success" | undefined
}

const TAlert = (props: IProps) => {
    const { message, variant } = props;

    const sx = {
        color: variant == "info" ? (theme: Theme) => theme.palette.text.primary : "#f0f4f8 !important"
    }

    return (
        <Stack spacing={1}>
            <Typography 
                level="body-sm" 
                startDecorator={alertIcon(variant as string)}
                sx={{ 
                    fontWeight: 600,
                    ...sx
                }}
            >
                {capitalizeFirstLetter(alertTitle(variant as string))}
            </Typography>
            <Typography level="body-sm" sx={sx} >
                {capitalizeFirstLetter(message)}
            </Typography>
        </Stack>
    )
}

const alertTitle = (variant: string) => {
    switch (variant) {
        case "success":
            return "Success";
        case "error":
            return "Error";
        default:
            return "Notification";
    }
}

const alertIcon = (variant: string) => {
    const sx: any = {
        fontSize: "0.90rem",
        color: variant === "info" ? (theme: Theme) => theme.palette.text.primary : "#f0f4f8"
    }

    switch (variant) {
        case "success":
            return <CheckRounded sx={sx} />;
        case "error":
            return <ErrorRounded sx={sx} />
        default:
            return <NotificationsRounded sx={sx} />
    }
}

export default TAlert