import { NotificationsRounded } from '@mui/icons-material';
import { Badge, Box, Button, Drawer, IconButton, ModalClose } from '@mui/joy';
import React, { useEffect, useState } from 'react'
import Messages from './includes/Messages';
import { useNotifications } from 'core/hooks/useNotifications';
import { getTokenOrDefault } from 'helpers/Storage';
import { useNotificationAction } from 'core/hooks/useNotificationAction';
import { useProfileContext } from 'contexts/ProfileContext';
import { getEnv } from 'helpers/Env';
import TAlert from 'components/common/TAlert';
import { pageAlert } from './utils';

const Notifications = () => {
    const [open, setOpen] = useState(false);
    const { profile } = useProfileContext();

    const token = getTokenOrDefault();
    const { notifications, count, getMessages, getCount } = useNotifications(token);
    const { clear, read } = useNotificationAction(token);

    useEffect(() => {
        const eventSource = new EventSource(`${getEnv("API_ENDPOINT")}/events/${profile?.account_id}`);
        eventSource.onmessage = async (event) => {
            if (event.data == '"NOTIFICATION"') {
                pageAlert(<TAlert message="You have a new message!" variant="info" />, "info");
                await getCount();
            }
        };

        return () => {
            eventSource.close();
        };
    }, []);

    useEffect(() => {
        if (open) {
            getMessages();
        }
        getCount()
    }, [open]);

    const handleClear = async () => {
        await clear();
        await getCount();
        await getMessages();
    }

    const handleRead = async () => {
        await read();
        await getCount();
        await getMessages();
    }

    return (
        <React.Fragment>
            <IconButton
                onClick={() => setOpen(true)}
                variant="plain"
                color="neutral"
                size="md"
            >
                <Badge badgeContent={count} showZero={false} size="sm">
                    <NotificationsRounded />
                </Badge>
            </IconButton>

            <Drawer
                open={open}
                onClose={() => { setOpen(false) }}
                anchor="right"
                size="sm"
                sx={{
                    zIndex: 1000,
                    overflow: "hidden",
                    '& .MuiDrawer-backdrop': {
                        backdropFilter: 'blur(1px)',
                    },
                }}
            >
                <ModalClose />
                <Box
                    role="presentation"
                    sx={{
                        padding: (theme) => theme.spacing(3),
                        height: "100%",
                        overflow: "hidden"
                    }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    gap={2}
                >
                    <Messages data={notifications} count={count} />

                    <Button size="sm" variant="outlined" color="primary" onClick={handleRead} disabled={notifications.length == 0}>
                        Mark All As Read
                    </Button>

                    <Button size="sm" variant="outlined" color="danger" onClick={handleClear} disabled={notifications.length == 0}>
                        Clear All
                    </Button>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export default Notifications