import { BrightnessAutoRounded } from '@mui/icons-material'
import { Box, Divider, IconButton, Typography } from '@mui/joy'
import React from 'react'
import ColorSchemeToggle from '../ColorSchemeToggle'

const Brand = () => {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <img src="https://gottit-media.b-cdn.net/s/logo_unspace.png" style={{width: "100%", maxWidth: "90px"}} />
                <ColorSchemeToggle sx={{ ml: 'auto' }} />
            </Box>
            <Divider />
        </React.Fragment>
    )
}

export default Brand