import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { initialize } from 'actions/security'
import { useRequestToken } from './useRequestToken'
import { enqueueSnackbar } from 'notistack'
import { IApp } from 'contexts/AppContext';
import { setModules } from 'helpers/Storage';
import { useModuleRoutes } from './useModuleRoutes';
import { useProfile } from './useProfile';
import { IProfile } from 'contexts/ProfileContext';
import { pageAlert } from 'views/layout/private/utils';
import TAlert from 'components/common/TAlert';

export const useAppInit = () => {
    const [app] = useState<IApp|null>(null);
    const [profile, setProfile] = useState<IProfile|null>(null);
    const [loading, setLoading] = useState(true);

    const { getToken } = useRequestToken();
    const { initModules } = useModuleRoutes();
    const { fetchProfile } = useProfile();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const response = await initialize();
            if (!response || response.status_code >= 500) {
                setLoading(false);
                navigate("/offline");
                return;
            }

            if (response.status_code !== 200) {
                setLoading(false);
                pageAlert(<TAlert message="Something went wrong" variant="error"/>, "error");
                navigate("/sign-in")
                return;
            }

            const data = response.data as IApp;
            localStorage.setItem("GOTTIT_VERSION", data.version as string);

            //return on actual pages
            if(["/reset-password","/forgot-password"].includes(location.pathname)) {
                setLoading(false);
                navigate(location);
                return;
            }

            //check user if authenticated
            const token = await getToken();
            if(token === null) {
                setLoading(false);
                navigate("/sign-in");
                return;
            }

            //get modules
            const modules = await initModules(token as string);
            if(modules === null) {
                pageAlert(<TAlert message="An error occured during fetching of modules" variant="error"/>, "error");
            }else {
                setModules(modules);
            }

            //get profile
            const me = await fetchProfile(token as string);
            if (me === null) {
                pageAlert(<TAlert message="Something went wrong" variant="error"/>, "error");
            }else {
                setProfile(me);
            }

            setLoading(false);
            navigate(location.pathname);
        } catch (err) {
            return navigate("/offline");
        }
    }

    return { app, profile, setProfile, loading }
}
