import { IParams, tryFetch } from "helpers/FetchAPI"

export const getProfile = async (token: string) => {
	const params: IParams = {
		url: "/g/me",
		method: "GET",
		token
	}
	return await tryFetch(params)
}

export const getNotifications = async (token: string) => {
	const params: IParams = {
		url: "/g/notifications",
		method: "GET",
		token
	}
	return await tryFetch(params)
}

export const getNotificationCount = async (token: string) => {
	const params: IParams = {
		url: "/g/notifications/count",
		method: "GET",
		token
	}
	return await tryFetch(params)
}

export const clearNotifications = async (token: string) => {
	const params: IParams = {
		url: "/g/notifications/clear",
		method: "POST",
		token
	}
	return await tryFetch(params)
}

export const readNotifications = async (token: string) => {
	const params: IParams = {
		url: "/g/notifications/read",
		method: "POST",
		token
	}
	return await tryFetch(params)
}

export const getUser = async (token: string) => {
	const params: IParams = {
		url: "/user",
		method: "GET",
		token
	};
	return await tryFetch(params);
};

export const getUsers = async (token: string) => {
	const params: IParams = {
		url: "/user",
		method: "GET",
		token
	};
	return await tryFetch(params);
};

export const getRoles = async (token: string) => {
	const params: IParams = {
		url: "/roles",
		method: "GET",
		token
	};
	return await tryFetch(params);
};

export const createTeamMember = async (values: any, token: string) => {
	const params: IParams = {
		url: "/new-user",
		method: "POST",
		token,
		payload: values,
	}
	return await tryFetch(params);
};

export const getTeamMemberDetails = async (id: string, token: string) => {
	const params: IParams = {
		url: `/user/${id}/edit`,
		method: "POST",
		token,
		payload: { id }
	}
	return await tryFetch(params);
}


export const deleteTeamMember = async (id: number, token: string) => {
	const params: IParams = {
		url: `/user/${id}/delete`,
		method: "POST",
		token,
		payload: { id },
	};
	return await tryFetch(params);
};

export const updateTeamMemberStatus = async (id: number, token: string) => {
	const params: IParams = {
		url: `/user/${id}/status`,
		method: "POST",
		token,
		payload: { id },
	};
	return await tryFetch(params);
};