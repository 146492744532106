import { AddModeratorOutlined, AdminPanelSettings, DashboardRounded, GroupRounded, SettingsRounded, SupportRounded } from "@mui/icons-material";
import { Theme } from "@mui/joy";
import { hash } from "helpers/String";
import { IMenuItem } from "interfaces/IMenu";

const sx: any = { color: (theme: Theme) => theme.palette.text.primary }

export const defaultTopMenu: IMenuItem[] = [
    {
        id: hash("_dashboard"),
        name: "Dashboard",
        path: "/dashboard",
        icon: <DashboardRounded sx={sx} />,
        children: [],
        divider: false
    }
];

export const defaultBottomMenu: IMenuItem[] = [
    {
        id: hash("_support"),
        name: "Support",
        path: "/support",
        icon: <SupportRounded sx={sx} />,
        children: [],
        divider: false
    },
];

export const defaultAdminMenu: IMenuItem[] = [
    {
        id: hash("_admin"),
        name: "Admin",
        path: "",
        icon: <AdminPanelSettings sx={sx} />,
        children: [
            {
                id: hash("_merchants"),
                name: "Merchants",
                path: "/merchants",
                icon: <AddModeratorOutlined sx={sx} />,
                children: [],
                divider: false
            }
        ],
        divider: false
    }
];