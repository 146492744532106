import { enqueueSnackbar, SnackbarMessage } from "notistack";

export function openSidebar() {
	if (typeof window !== 'undefined') {
		document.body.style.overflow = 'hidden';
		document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
	}
}

export function closeSidebar() {
	if (typeof window !== 'undefined') {
		document.documentElement.style.removeProperty('--SideNavigation-slideIn');
		document.body.style.removeProperty('overflow');
	}
}

export function toggleSidebar() {
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		const slideIn = window
			.getComputedStyle(document.documentElement)
			.getPropertyValue('--SideNavigation-slideIn');
		if (slideIn) {
			closeSidebar();
		} else {
			openSidebar();
		}
	}
}

export function pageAlert(component: SnackbarMessage, variant: "info" | "default" | "error" | "warning" | "success" | undefined) {
	return enqueueSnackbar(component, { variant: variant, hideIconVariant: true })
}