import Loadable from 'components/Loadable'
import React, { lazy } from 'react'
import PublicLayout from 'views/layout/public/Index'

const SignIn = Loadable(lazy(() => import("views/pages/public/SignIn")));
const Reset = Loadable(lazy(() => import("views/pages/public/Reset")));
const Offline = Loadable(lazy(() => import("views/pages/public/Offline")))

const PublicRoutes =  {
    path: "/",
    element: <PublicLayout/>,
    children: [
        {
            path: "sign-in",
            element: <SignIn/>
        },
        {
            path: "reset-password",
            element: <Reset/>
        },
        {
            path: "offline",
            element: <Offline/>
        }
    ]

}

export default PublicRoutes