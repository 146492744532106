import React, { useEffect } from 'react';
import { useAppInit } from './hooks/useAppInit';
import AppRoutes from 'routes';
import { SnackbarProvider } from 'notistack';
import AppContextProvider from 'contexts/AppContext';
import ProfileContextProvider from 'contexts/ProfileContext';
import { Box, CircularProgress } from '@mui/joy';

const Wrapper = () => {
    const { app, profile, setProfile, loading } = useAppInit();
    return (
        <SnackbarProvider
            maxSnack={3}
            style={{ zIndex: 9999 }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
        >
            <AppContextProvider value={app}>
                {!loading ? (
                    <ProfileContextProvider profile={profile} setProfile={setProfile}>
                        <AppRoutes />
                    </ProfileContextProvider>
                ) : <PageLoading />}
            </AppContextProvider>
        </SnackbarProvider>
    )
}

const PageLoading = () => {
    return (
        <Box sx={{ width: "100%", height: "100%" }} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress
                sx={{
                    "--CircularProgress-size": "50px",
                    "--CircularProgress-trackThickness": "2px",
                    "--CircularProgress-progressThickness": "2px"
                }}
            />
        </Box>
    )
}

export default Wrapper