import React from 'react';
import { useAppInit } from './hooks/useAppInit';
import AppRoutes from 'routes';
import AppContextProvider from 'contexts/AppContext';
import ProfileContextProvider from 'contexts/ProfileContext';
import TSnackbar from 'components/common/TSnackbar';
import PageLoading from 'components/PageLoading';

const Wrapper = () => {
    const { app, profile, setProfile, loading } = useAppInit();
    return (
        <TSnackbar>
            <AppContextProvider value={app}>
                {!loading ? (
                    <ProfileContextProvider profile={profile} setProfile={setProfile}>
                        <AppRoutes />
                    </ProfileContextProvider>
                ) : <PageLoading />}
            </AppContextProvider>
        </TSnackbar>
    )
}

export default Wrapper