import { getNotificationCount, getNotifications } from 'actions/user';
import React, { useState } from 'react'

export interface INotification {
    id: string,
    reference_type: string,
    reference_id: string,
    message_type: string,
    subject: string,
    message: string,
    is_read: boolean,
    created_at: string
}

export const useNotifications = (token: string) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [count, setCount] = useState<any>(0);

    const getMessages = async () => {
        const result= await getNotifications(token);
        if(result.is_success && result.data !== null) {
            setNotifications(result.data as INotification[]);
            return;
        }
        setNotifications([]);
    }

    const getCount = async () => {
        const result= await getNotificationCount(token);
        if(result.is_success && result.data !== null) {
            setCount(result.data ?? 0);
        }
    }

    return { notifications, count, getMessages, getCount }
}