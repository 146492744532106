import { Check, Error, NotificationsRounded, Warning } from '@mui/icons-material';
import { styled, Theme, useTheme } from '@mui/joy'
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import React from 'react'

interface IProps {
    children: any
}

const StyledSnackbar = styled(MaterialDesignContent)(() => {
    const theme = useTheme();

    return ({
        '&.notistack-MuiContent-info': {
            backgroundColor: theme.palette.background.popup,
        },
        '&.notistack-MuiContent-default': {
            backgroundColor: theme.palette.neutral[500]
        },
        '&.notistack-MuiContent-error': {
            backgroundColor: theme.palette.danger[500]
        },
        '&.notistack-MuiContent-warning': {
            backgroundColor: theme.palette.warning[500]
        },
        '&.notistack-MuiContent-success': {
            backgroundColor: theme.palette.success[500]
        },
    })
});

const TSnackbar = (props: IProps) => {
    const { children } = props;
    const theme = useTheme();

    return (
        <SnackbarProvider
            maxSnack={3}
            style={{
                zIndex: 9999
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            Components={{
                info: StyledSnackbar,
                default: StyledSnackbar,
                error: StyledSnackbar,
                warning: StyledSnackbar,
                success: StyledSnackbar
            }}
        >
            {children}
        </SnackbarProvider>
    )
}

export default TSnackbar